
import { Component, Vue } from 'vue-property-decorator';
import { BasePage, HomePage, PageSections, Widget, Widgets } from '@ne/ne-mul-interface-api';
import { NeLayoutHomePage } from '@ne/ne-vue2-lib';
import { DynamicWidget } from '@/shared/components';

const widgetHomePageResponsiveOptionsMock = [
  {
    breakpoint: 360,
    itemsToShow: 5
  },

  {
    breakpoint: 375,
    itemsToShow: 1
  },

  {
    breakpoint: 425,
    itemsToShow: 2
  },

  {
    breakpoint: 768,
    itemsToShow: 3
  },

  {
    breakpoint: 992,
    itemsToShow: 2
  },

  {
    breakpoint: 1200,
    itemsToShow: 3
  }
];
@Component({
  components: {
    DynamicWidget,
    NeLayoutHomePage
  }
})
export default class Preview extends Vue {
  homePageData: BasePage | null = null;
  widgets = [] as Widget<Widgets>[];
  topSections: Widget<Widgets>[] | undefined;

  async created (): Promise<void> {
    await this.fetchHomePage();
  }

  async fetchHomePage (): Promise<void> {
    const apiUrl = this.$route.query.apiUrl as string;
    this.homePageData = (await this.$apiMul.get(apiUrl)).data as BasePage;
    this.widgets = (this.homePageData!.sections as PageSections).content;
    this.topSections = (this.homePageData!.sections as PageSections).top ? (this.homePageData!.sections as PageSections).top : [];
  }

  get responsiveOptions () {
    return widgetHomePageResponsiveOptionsMock;
  }

  get sliderSection () {
    return (this.topSections![0] as Widget<HomePage>)?.attributes?.sliderSection || [];
  }

  get brandingSection () {
    return (this.topSections![0] as Widget<HomePage>)?.attributes?.brandingSection || [];
  }

  get description () {
    return (this.topSections![0] as Widget<HomePage>)?.attributes?.description || '';
  }

  get background () {
    return (this.topSections![0] as Widget<HomePage>)?.attributes?.backgroundColor || '';
  }

  get displayStyle () {
    return (this.topSections![0] as Widget<HomePage>)?.attributes?.displayStyle || '';
  }

  get accentColor () {
    return (this.topSections![0] as Widget<HomePage>)?.attributes?.accentColor || '';
  }
}
